<template>
  <div class="app-main-content">
    <card title="任务" :tabs="cat.issue_bstatus" v-model="listQuery.issue_status"
          body-class="overflow-hidden page-card-body" @change="search">
      <template #tools>
        <div class="right">
          <div class="item">
            <span class="label">排序</span>
            <a-select @change="search" class="sort-select" placeholder="请选择" style="width: 160px"
                      v-model="listQuery.orderby">
              <a-select-option value="a.create_time desc">创建时间倒序</a-select-option>
              <a-select-option value="a.create_time asc">创建时间正序</a-select-option>
              <a-select-option value="a.issue_level desc">优先级倒序</a-select-option>
              <a-select-option value="a.issue_level asc">优先级正序</a-select-option>
              <a-select-option value="a.schedule_start_date asc,a.schedule_end_date asc">计划开始时间正序</a-select-option>
              <a-select-option value="a.schedule_start_date desc">计划开始时间倒序</a-select-option>
              <a-select-option value="a.schedule_end_date asc">计划结束时间正序</a-select-option>
              <a-select-option value="a.schedule_end_date desc">计划结束时间倒序</a-select-option>
            </a-select>
          </div>
          <div class="item">
            <span class="label">搜索</span>
            <a-input placeholder="请输入标题" v-model="listQuery.keywords">
              <a-icon slot="prefix" type="search" />
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="search">查询</a-button>
          </div>
        </div>
      </template>
      <div class="body-flex">
        <div class="body-left-table">
          <a-table
            v-if="renderTable"
            :defaultExpandAllRows="true"
            :data-source="list"
            rowKey="oid"
            :expandIconColumnIndex="1"
            :pagination="pagination"
            @change="paginationChange"
            :customRow="customRow"
            :expandedRowKeys.sync="expandKeys"
          >
            <a-table-column title="ID" data-index="seqid" width="75px" >
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="标题" width="300px" data-index="标题">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <img v-if="row.is_later === '1' || row.issue_status === '10'" :src="$toUrl('task_red.png')" alt="">
                  <img v-else-if="row.issue_status === '0'" :src="$toUrl('task_blue.png')" alt="">
                  <img v-else-if="row.issue_status === '1'" :src="$toUrl('task_yellow.png')" alt="">
                  <img v-else-if="row.issue_status === '100'" :src="$toUrl('task_green.png')" alt="">
                  <span class="name">{{ row.issue_name }}</span>
                  <a-tooltip>
                    <template slot="title">新增子任务</template>
                    <img style="cursor: pointer;height: 16px;" :src="$toUrl('mini-add.png')" class="hidden" alt="" @click="openChildrenTaskModal(row)">
                  </a-tooltip>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="优先级" data-index="优先级" width="80px">
              <template v-slot:default="_, row">
                <div
                    v-if="row.issue_level"
                    class="edp-tag"
                    :class="{
                    green: row.issue_level === '100',
                    yellow: row.issue_level === '300',
                    blue: row.issue_level === '200',
                    red: row.issue_level === '400',
                  }"
                >
                  {{ row.issue_level_name }}
                </div>
                <div v-else class="edp-tag">未设置</div>
              </template>
            </a-table-column>
            <a-table-column title="状态" data-index="issue_status_name" width="70px">
              <template v-slot:default="_, row">
                <div
                  class="edp-status"
                  :class="{
                    blue: row.issue_status === '0',
                    yellow: row.issue_status === '1',
                    green: row.issue_status === '100',
                    gray: row.issue_status === '1000',
                  }"
                >
                  {{ row.issue_status_name }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="负责人" width="70px">
              <template v-slot:default="_, row">
                <div class="edp-owners">
                  <div v-for="item in row.owners" :key="item.oid">
                    <a-avatar :size="16" :src="item.pic" />
                    <span>{{ item.name }}</span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="进度" width="60px">
              <template v-slot="_, row">
                <div>{{row.complete_percent || 0}}%</div>
              </template>
            </a-table-column>
            <a-table-column title="计划结束" data-index="schedule_end_date" width="80px">
            </a-table-column>
            <a-table-column title="实际结束" data-index="actual_end_date" width="80px">
            </a-table-column>
          </a-table>
        </div>
        <issue-detail ref="issueDetail" :oid="detailOid" @reload="search" position="supplier"></issue-detail>
      </div>
    </card>
    <create-task-modal @reload="reload" ref="taskModal"></create-task-modal>
    <import-file url="/prj/issue/import" :query="{prjoid: $route.query.prj_oid}" ref="import" @response="importSuccess"></import-file>
  </div>
</template>

<script>
import CreateTaskModal from "@/components/createTaskModal.vue";
import listMixin from "@/utils/mixins/listMixin.js";
import catMixin from "@/utils/mixins/catMixin";
import {fetch} from "@/utils/request.js";
import IssueDetail from "@/components/issueDetail.vue";
import {getTemplate, queryChildren} from "@/utils/helpFunc";
import importFile from "@/components/importFile.vue";

export default {
  components: { IssueDetail, CreateTaskModal, importFile },
  mixins: [listMixin, catMixin],
  data() {
    return {
      comment: "",
      detailOid: "",
      cat: {
        issues_level: [],
        issue_bstatus: []
      },
      listMixinOptions: {
        mockUrl: "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/retrieve/toplevel/byprj",
        url: "/prj/issue/retrieve"
      },
      listQuery: {
        issue_type: "T",
        prjoid: this.$route.query.prj_oid,
        keywords: "",
        orderby: "a.schedule_start_date asc,a.schedule_end_date asc",
        issue_status: ""
      },
      selectedRowKeys: [],
      commentList: [],
      accountList: [],
      expandKeys: [],
      createIdOrder: false
    }
  },
  watch: {
    list: {
      deep: true,
      handler(val) {
        queryChildren(val)
      }
    }
  },
  created() {
    this.getAllAccount()
    if (this.$route.query.oid) {
      this.detailOid = this.$route.query.oid
    }
  },
  methods: {
    reload() {
      this.search()
      this.$refs.issueDetail.reloadMoneyModalIssueList()
    },
    getAllAccount() {
      fetch("post", "/cpyaccount/retrieve/bycompany").then(res => {
        if (res.status === 0) {
          this.accountList = res.data.datas
        }
      })
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.detailOid = record.oid
          }
        }
      }
    },
    async getChildren(record) {
      let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/retrieve/children?apifoxApiId=59781388" : "/prj/issue/retrieve/children"
      await fetch("post", url, {
        oid: record.oid
      }).then(async res => {
        for (let i = 0; i < res.data.length; i++) {
          record.children.push({
            ...res.data[i],
            children: this.expandKeys.includes(res.data[i].oid) ? await this.getChildren() : []
          })
        }
      })
    },
    async expand(expanded, record) {
      if (expanded && record.children.length === 0) {
        await this.getChildren(record)
      }
    },
    async listFilter(data) {
      // for (let i=0; i< data.length; i++) {
      //   data[i].children = []
      //   if (this.expandKeys.includes(data[i].oid)) {
      //     await this.getChildren(data[i])
      //   }
      // }
      return data
    },
    openModal() {
      this.$refs.taskModal.open({
        prjoid: this.$route.query.prj_oid
      })
      this.$refs.taskModal.disableProject = true
    },
    selectActionMenu({ key }) {
      if (key === "import") {
        this.$refs.import.upload()
      } else if (key === "export") {
        // console.log(this.list.map(item => item.oid).join(','));
        if (this.list.length > 0) {
          window.open(this.$toUrl(`/prj/issue/export?issueoids=${this.list.map(item => item.oid).join(',')}`, true, "api"))
        }else {
          this.$message.error('暂无任务')
        }
      } else if (key === "download") {
        getTemplate('/attachment/model', '任务项导入模板.xlsx')
      }
    },
    beforeGetList() {
      if (!this.detailOid) {
        this.detailOid = this.list?.[0]?.oid
      }
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    },
    setSortQuery(sort) {
      this.createIdOrder = sort.order
      console.log(sort);
      if (sort.order === "descend") {
        this.listQuery.orderby = sort.field + ' desc'
      } else if (sort.order === "ascend") {
        this.listQuery.orderby = sort.field + ' asc'
      } else {
        this.listQuery.orderby = ""
      }
    },
    openChildrenTaskModal(row) {
      this.$refs.taskModal.open({
        prjoid: row.prjoid,
        route_level: row.route_level - 0 + 1,
        route_map: row.route_map + '/' + row.oid,
        phase_oid: row.phase_oid
      })
    },
    importSuccess(res) {
      if (res.status === 0) {
        this.$message.success("导入成功")
        this.getList()
      } else {
        this.$message.error(res.message)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.app-main-content {
  /deep/ .page-card-body {
    padding: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    // 表格上方的操作栏
    > .body-action {
      display: flex;
      border-bottom: 1px solid #eee;

      .right {
        align-items: center;
        display: flex;
        margin-left: auto;
        margin-bottom: 10px;

        .item {
          margin-left: 20px;

          .label {
            margin-right: 10px;
          }
        }
      }
    }

    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      width: 100%;
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;
      // 左侧表格
      > .body-left-table {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        background: #FFF;
      }
    }
  }
}
</style>
