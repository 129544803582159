import { render, staticRenderFns } from "./supplierTask.vue?vue&type=template&id=303aa988&scoped=true&"
import script from "./supplierTask.vue?vue&type=script&lang=js&"
export * from "./supplierTask.vue?vue&type=script&lang=js&"
import style0 from "./supplierTask.vue?vue&type=style&index=0&id=303aa988&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "303aa988",
  null
  
)

export default component.exports